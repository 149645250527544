import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useSearchParams } from 'react-router-dom';
import {
  Box,
  Container,
  Avatar,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  TableFooter,
  TablePagination,
  TextField,
  Divider,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserListSkelet from '../../skeletons/UserListSkelet';
import { useDelete, useGet, usePost } from '../../API/request';
import { useConfirm } from '../../components/Confirm/index';
import { BallTriangle } from 'react-loader-spinner';
import '../../styles/All.css';
import { SettingsInputSvideoSharp } from '@material-ui/icons';
import { setSeconds } from 'date-fns';
import ArrowSort from '../../icons/ArrowSort';

const ProbesList = () => {
  const confirm = useConfirm();
  const getU = useGet();
  const postU = usePost();
  const deleteU = useDelete();
  const [searchParams, setSearchParams] = useSearchParams({
    sort: "id",
    order: "desc"
  });

  const [isLoaded, setIsLoaded] = useState(true);

  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);
  const [companies, setCompanies] = useState([]);
  const [users, setUsers] = useState([]);

  const [queryParams, setQueryParams] = useState({
    companyId: searchParams.get('companyId') || '',
    userId: searchParams.get('userId') || '',
    page: searchParams.get('page') || 0,
    limit: searchParams.get('limit') || 10,
    sort: searchParams.get('sort') || 'id',
    order: searchParams.get('order') || 'desc'
  });

  const [alert, setAlert] = useState({
    txt: '',
    isVisible: false,
    type: 'error'
  });

  const showAlert = (type, text) => {
    setAlert({
      txt: text,
      type,
      isVisible: true
    });

    setTimeout(() => {
      setAlert({
        txt: text,
        type,
        isVisible: false
      });
    }, 2500);
  };

  const handleChangeQueryParams = (event) => {
    setQueryParams({
      ...queryParams,
      [event.target.name]: event.target.value
    });
  };

  const handleFilterQueryParams = () => {
    const params = {};
    if (queryParams.companyId !== '') {
      params.companyId = queryParams.companyId;
    }
    if (queryParams.userId !== '') {
      params.userId = queryParams.userId;
    }

    if (Object.keys(params).length !== 0) {
      // setSearchParams(params);
      setSearchParams({
        ...params,
        page: 0,
        limit: 10
      });
      setPage(0);
      setLimit(10);
    }
  };

  const loadUsers = () => {
    setIsLoaded(true);

    let endpoint = `probes?page=${
      Number(searchParams.get('page')) + 1
    }&limit=${Number(searchParams.get('limit'))}&sort=${
      searchParams.get('sort') || 'id'
    }&order=${searchParams.get('order') || 'desc'}`;

    if (queryParams.companyId && searchParams.get('companyId') !== null) {
      endpoint += `&companyId=${searchParams.get('companyId')}`;
    } else {
      setQueryParams({
        ...queryParams,
        companyId: ''
      });
    }

    if (queryParams.userId && searchParams.get('userId') !== null) {
      endpoint += `&userId=${searchParams.get('userId')}`;
    } else {
      setQueryParams({
        ...queryParams,
        userId: ''
      });
    }

    getU(endpoint)
      .then((resp) => {
        if (resp.status === 'success') {
          setData(resp.data.probes);
          setCount(resp.data.currentCount || 0);
        } else {
          showAlert(
            'error',
            'Произошла ошибка при получении проб, повторите попытку позже'
          );
        }
      })
      .catch((err) => {
        console.log(err.response);
        setData([]);
        setCount(0);
      })
      .finally(() => {
        setIsLoaded(false);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (queryParams.userId !== '') {
      setSearchParams({
        userId: queryParams.userId,
        page: newPage,
        limit:
          Number(searchParams.get('limit')) === 0
            ? 10
            : Number(searchParams.get('limit')),
        sort: searchParams.get('sort'),
        order: searchParams.get('order')
      });
    } else if (queryParams.companyId !== '') {
      setSearchParams({
        companyId: queryParams.companyId,
        page: newPage,
        limit:
          Number(searchParams.get('limit')) === 0
            ? 10
            : Number(searchParams.get('limit')),
        sort: searchParams.get('sort'),
        order: searchParams.get('order')
      });
    } else {
      setSearchParams({
        page: newPage,
        limit:
          Number(searchParams.get('limit')) === 0
            ? 10
            : Number(searchParams.get('limit')),
        sort: searchParams.get('sort'),
        order: searchParams.get('order')
      });
    }
  };

  const handleChangeLimit = (event) => {
    setLimit(event.target.value);
    setPage(0);
    if (queryParams.userId !== '') {
      setSearchParams({
        userId: queryParams.userId,
        page: 1,
        limit: event.target.value,
        sort: searchParams.get('sort'),
        order: searchParams.get('order')
      });
    } else if (queryParams.companyId !== '') {
      setSearchParams({
        companyId: queryParams.companyId,
        page: 0,
        limit: event.target.value,
        sort: searchParams.get('sort'),
        order: searchParams.get('order')
      });
    } else {
      setSearchParams({
        page: 0,
        limit: event.target.value,
        sort: searchParams.get('sort'),
        order: searchParams.get('order')
      });
    }
  };

  const handleChangeSort = (sort) => {
    console.log(sort);
    setSearchParams({ ...searchParams, sort });

    let order = searchParams.get('order');

    if (sort !== searchParams.get('sort')) order = 'desc';
    else order = 'desc' === order ? 'asc' : 'desc';

    setSearchParams({ ...searchParams, sort, order });
  };

  const onDelete = (id) => {
    confirm({
      title: 'Удаление',
      content: 'Вы уверены, что хотите удалить пробу?',
      onConfirm: () => {
        deleteU(`probes/${id}`)
          .then((resp) => {
            if (resp.status === 'success') {
              loadUsers();
            } else {
              showAlert('error', 'Произошла ошибка при попытке удалить пробы');
            }
          })
          .catch((e) => {
            showAlert('error', e.response.data.message);
          });
      }
    });
  };

  useEffect(() => {
    if (queryParams.companyId === '') {
      searchParams.delete('companyId');
      setSearchParams(searchParams);
    }
    if (queryParams.userId === '') {
      searchParams.delete('userId');
      setSearchParams(searchParams);
    }
  }, [queryParams]);

  useEffect(() => {
    loadUsers();
  }, [searchParams]);

  useEffect(() => {
    setIsLoaded(true);
    getU(`companies`)
      .then((resp) => {
        if (resp.status === 'success') {
          setCompanies(resp.data.companies);
        }
      })
      .catch(() => {
        showAlert('error', 'Произошла ошибка при загрузке компаний');
      })
      .finally(() => {
        setIsLoaded(false);
      });
  }, []);

  useEffect(() => {
    setIsLoaded(true);
    getU(`companies?limit=all`)
      .then((resp) => {
        if (resp.status === 'success') {
          setCompanies(resp.data.companies);
        }
      })
      .catch(() => {
        showAlert('error', 'Произошла ошибка при загрузке компаний');
      })
      .finally(() => {
        setIsLoaded(false);
      });
  }, []);

  useEffect(() => {
    setIsLoaded(true);
    getU(`admin/users?limit=all`)
      .then((resp) => {
        if (resp.status === 'success') {
          setUsers(resp.data.users);
        }
      })
      .catch(() => {
        showAlert('error', 'Произошла ошибка при загрузке пользователей');
      })
      .finally(() => {
        setIsLoaded(false);
      });
  }, []);

  if (isLoaded) {
    return (
      <div className="loader">
        <BallTriangle
          height="100"
          width="100"
          color="grey"
          ariaLabel="loading"
        />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Пробы</title>
      </Helmet>
      <Box className="headerWrapper">
        <Box className="headerTitle">Пробы</Box>
      </Box>
      <Box
        sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}
      >
        <Container maxWidth={false}>
          <Alert
            severity={alert.type}
            style={{
              display: alert.isVisible ? 'flex' : 'none',
              marginBottom: 10
            }}
          >
            {alert.txt}
          </Alert>
          <Box sx={{ pt: 3 }}>
            <Card>
              <PerfectScrollbar>
                <Box sx={{ minWidth: 1500 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      mx: 2,
                      mb: 1
                    }}
                  >
                    <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
                      <InputLabel id="company">Компания</InputLabel>
                      <Select
                        labelId="Company"
                        name="companyId"
                        value={queryParams.companyId}
                        label="Компания"
                        onChange={handleChangeQueryParams}
                      >
                        {[{ id: '', name: 'Все' }, ...companies]?.map(
                          (item) => (
                            <MenuItem value={item.id}>{item.name}</MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>

                    <FormControl fullWidth sx={{ mt: 2, mb: 1, ml: 2 }}>
                      <InputLabel id="user">Пользователь</InputLabel>
                      <Select
                        labelId="User"
                        name="userId"
                        value={queryParams.userId}
                        label="Компания"
                        onChange={handleChangeQueryParams}
                      >
                        {[{ id: '', name: 'Все' }, ...users]?.map((item) => (
                          <MenuItem value={item.id}>{item.email}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={handleFilterQueryParams}
                      sx={{ mt: 2, mb: 1, ml: 2 }}
                      style={{ minWidth: 120 }}
                    >
                      Применить
                    </Button>
                  </Box>
                  <Divider />
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{ width: 80, cursor: 'pointer' }}
                          onClick={() => handleChangeSort('id')}
                        >
                          Id
                          {searchParams.get('sort') === 'id' ? (
                            <ArrowSort
                              rotate={
                                searchParams.get('order') == 'desc' ? 90 : 270
                              }
                            />
                          ) : (
                            <></>
                          )}
                        </TableCell>
                        <TableCell
                          sx={{ cursor: 'pointer' }}
                          onClick={() => handleChangeSort('code')}
                        >
                          Шифр{''}
                          {searchParams.get('sort') === 'code' ? (
                            <ArrowSort
                              rotate={
                                searchParams.get('order') == 'desc' ? 90 : 270
                              }
                            />
                          ) : (
                            <></>
                          )}
                        </TableCell>
                        <TableCell
                          sx={{ cursor: 'pointer' }}
                          onClick={() => handleChangeSort('object')}
                        >
                          Объект
                          {searchParams.get('sort') === 'object' ? (
                            <ArrowSort
                              rotate={
                                searchParams.get('order') == 'desc' ? 90 : 270
                              }
                            />
                          ) : (
                            <></>
                          )}
                        </TableCell>
                        <TableCell
                          sx={{ cursor: 'pointer' }}
                          onClick={() =>
                            handleChangeSort('objectCharacteristics')
                          }
                        >
                          Характеристика объекта
                          {searchParams.get('sort') === 'objectCharacteristics' ? (
                            <ArrowSort
                              rotate={
                                searchParams.get('order') == 'desc' ? 90 : 270
                              }
                            />
                          ) : (
                            <></>
                          )}
                        </TableCell>
                        <TableCell
                          sx={{ cursor: 'pointer' }}
                          onClick={() => handleChangeSort('vendorCode')}
                        >
                          Артикул
                          {searchParams.get('sort') === 'vendorCode' ? (
                            <ArrowSort
                              rotate={
                                searchParams.get('order') == 'desc' ? 90 : 270
                              }
                            />
                          ) : (
                            <></>
                          )}
                        </TableCell>
                        <TableCell
                          sx={{ cursor: 'pointer' }}
                          onClick={() => handleChangeSort('companyId')}
                        >
                          Компания
                          {searchParams.get('sort') === 'companyId' ? (
                            <ArrowSort
                              rotate={
                                searchParams.get('order') == 'desc' ? 90 : 270
                              }
                            />
                          ) : (
                            <></>
                          )}
                        </TableCell>
                        <TableCell
                          sx={{ cursor: 'pointer' }}
                          onClick={() => handleChangeSort('userId')}
                        >
                          Пользователь
                          {searchParams.get('sort') === 'userId' ? (
                            <ArrowSort
                              rotate={
                                searchParams.get('order') == 'desc' ? 90 : 270
                              }
                            />
                          ) : (
                            <></>
                          )}
                        </TableCell>
                        <TableCell
                          sx={{ cursor: 'pointer' }}
                          onClick={() => handleChangeSort('acceptanceDate')}
                        >
                          Дата приемки
                          {searchParams.get('sort') === 'acceptanceDate' ? (
                            <ArrowSort
                              rotate={
                                searchParams.get('order') == 'desc' ? 90 : 270
                              }
                            />
                          ) : (
                            <></>
                          )}
                        </TableCell>
                        <TableCell
                          sx={{ cursor: 'pointer' }}
                          onClick={() => handleChangeSort('utilizationDate')}
                        >
                          Дата утилизации
                          {searchParams.get('sort') === 'utilizationDate' ? (
                            <ArrowSort
                              rotate={
                                searchParams.get('order') == 'desc' ? 90 : 270
                              }
                            />
                          ) : (
                            <></>
                          )}
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data?.map((item) => (
                        <TableRow hover key={item.id}>
                          <TableCell sx={{ width: 80 }}>{item?.id}</TableCell>
                          <TableCell>{item?.code || '---'}</TableCell>
                          <TableCell>{item?.object || '---'}</TableCell>
                          <TableCell>
                            {item?.objectCharacteristics || '---'}
                          </TableCell>
                          <TableCell>{item?.vendorCode || '---'}</TableCell>
                          <TableCell>
                            {item?.companyId ? (
                              <Link
                                to={`/app/company/info/${item.companyId}`}
                                target="_blank"
                              >
                                {item?.company?.name}
                              </Link>
                            ) : (
                              '---'
                            )}
                          </TableCell>
                          <TableCell>
                            {item?.userId ? (
                              <Link
                                to={`/app/user/${item.userId}`}
                                target="_blank"
                              >
                                {item?.user?.email}
                              </Link>
                            ) : (
                              '---'
                            )}
                          </TableCell>
                          <TableCell>
                            {item?.acceptanceDate
                              ? new Date(item?.acceptanceDate).toLocaleString()
                              : '---'}
                          </TableCell>
                          <TableCell>
                            {item?.utilizationDate
                              ? new Date(item?.utilizationDate).toLocaleString()
                              : '---'}
                          </TableCell>
                          <TableCell>
                            <Box sx={{ display: 'flex' }}>
                              <Box sx={{ display: 'flex' }}>
                                <Box sx={{ ml: 2 }}>
                                  <Link to={`/app/probe/info/${item?.id}`}>
                                    <Button color="primary" variant="contained">
                                      Инфо.
                                    </Button>
                                  </Link>
                                </Box>
                                <Box sx={{ ml: 2 }}>
                                  <Button
                                    color="error"
                                    variant="contained"
                                    onClick={() => onDelete(item.id)}
                                  >
                                    Удалить
                                  </Button>
                                </Box>
                              </Box>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          labelRowsPerPage={
                            <span>Кол-во строк на странице:</span>
                          }
                          rowsPerPageOptions={[10, 20, 30, 40, 50]}
                          colSpan={7}
                          count={count}
                          page={Number(searchParams.get('page'))}
                          rowsPerPage={
                            Number(searchParams.get('limit')) === 0
                              ? 10
                              : Number(searchParams.get('limit'))
                          }
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeLimit}
                        />
                        {console.log(Number(searchParams.get('limit')))}
                      </TableRow>
                    </TableFooter>
                  </Table>
                </Box>
              </PerfectScrollbar>
            </Card>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default ProbesList;
