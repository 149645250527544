import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useSearchParams } from 'react-router-dom';
import {
  Box,
  Container,
  Avatar,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  TableFooter,
  TablePagination,
  TextField,
  Divider,
  Alert
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserListSkelet from '../../skeletons/UserListSkelet';
import { useDelete, useGet, usePost } from '../../API/request';
import { useConfirm } from '../../components/Confirm/index';
import { BallTriangle } from 'react-loader-spinner';
import '../../styles/All.css';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import ModalErrors from '../../components/ModalErrors/ModalErrors';

const CompanyList = () => {
  const confirm = useConfirm();
  const getU = useGet();
  const postU = usePost();
  const deleteU = useDelete();
  const [searchParams, setSearchParams] = useSearchParams();

  const [isLoaded, setIsLoaded] = useState(true);

  const [modalErrors, setModalErrors] = useState({
    isOpen: false,
    errors: null
  });

  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);

  const [queryParams, setQueryParams] = useState({
    name: searchParams.get('name') || '',
    inn: searchParams.get('inn') || '',
    page: searchParams.get('page') || 0,
    limit: searchParams.get('limit') || 10
  });

  const [alert, setAlert] = useState({
    txt: '',
    isVisible: false,
    type: 'error'
  });

  const showAlert = (type, text) => {
    setAlert({
      txt: text,
      type,
      isVisible: true
    });

    setTimeout(() => {
      setAlert({
        txt: text,
        type,
        isVisible: false
      });
    }, 2500);
  };

  const handleChangeQueryParams = (event) => {
    setQueryParams({
      ...queryParams,
      [event.target.name]: event.target.value
    });
  };

  const handleFilterQueryParams = () => {
    const params = {};
    if (queryParams.name !== '') {
      params.name = queryParams.name;
    }
    if (queryParams.inn !== '') {
      params.inn = queryParams.inn;
    }

    if (Object.keys(params).length !== 0) {
      // setSearchParams(params);
      setSearchParams({
        ...params,
        page: 0,
        limit: 10
      });
      setPage(0);
      setLimit(10);
    }
  };

  const loadUsers = () => {
    setIsLoaded(true);

    let endpoint = `companies?page=${
      Number(searchParams.get('page')) + 1
    }&limit=${Number(searchParams.get('limit'))}`;

    if (queryParams.name && searchParams.get('name') !== null) {
      endpoint += `&name=${searchParams.get('name')}`;
    } else {
      setQueryParams({
        ...queryParams,
        name: ''
      });
    }

    if (queryParams.inn && searchParams.get('inn') !== null) {
      endpoint += `&inn=${searchParams.get('inn')}`;
    } else {
      setQueryParams({
        ...queryParams,
        inn: ''
      });
    }

    getU(endpoint)
      .then((resp) => {
        if (resp.status === 'success') {
          setData(resp.data.companies);
          setCount(resp.data.currentCount || 0);
        } else {
          showAlert(
            'error',
            'Произошла ошибка при получении компаний, повторите попытку позже'
          );
        }
      })
      .catch((err) => {
        console.log(err.response);
        setData([]);
        setCount(0);
      })
      .finally(() => {
        setIsLoaded(false);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (queryParams.name !== '') {
      setSearchParams({
        name: queryParams.name,
        page: newPage,
        limit:
          Number(searchParams.get('limit')) === 0
            ? 10
            : Number(searchParams.get('limit'))
      });
    } else if (queryParams.inn !== '') {
      setSearchParams({
        inn: queryParams.inn,
        page: newPage,
        limit:
          Number(searchParams.get('limit')) === 0
            ? 10
            : Number(searchParams.get('limit'))
      });
    } else {
      setSearchParams({
        page: newPage,
        limit:
          Number(searchParams.get('limit')) === 0
            ? 10
            : Number(searchParams.get('limit'))
      });
    }
  };

  const handleChangeLimit = (event) => {
    setLimit(event.target.value);
    setPage(0);
    if (queryParams.name !== '') {
      setSearchParams({
        name: queryParams.name,
        page: 1,
        limit: event.target.value
      });
    } else if (queryParams.inn !== '') {
      setSearchParams({
        inn: queryParams.inn,
        page: 0,
        limit: event.target.value
      });
    } else {
      setSearchParams({
        page: 0,
        limit: event.target.value
      });
    }
  };

  const onDelete = (id) => {
    confirm({
      title: 'Удаление',
      content: 'Вы уверены, что хотите удалить компанию?',
      secondContent: 'Все пробы данной компании будут удалены.',
      onConfirm: () => {
        deleteU(`companies/${id}`)
          .then((resp) => {
            if (resp.status === 'success') {
              loadUsers();
            } else {
              showAlert(
                'error',
                'Произошла ошибка при попытке удалить компанию'
              );
            }
          })
          .catch((e) => {
            showAlert('error', e.response.data.message);
          });
      }
    });
  };

  useEffect(() => {
    if (queryParams.name === '') {
      searchParams.delete('name');
      setSearchParams(searchParams);
    }
    if (queryParams.inn === '') {
      searchParams.delete('inn');
      setSearchParams(searchParams);
    }
  }, [queryParams]);

  useEffect(() => {
    loadUsers();
  }, [searchParams]);

  if (isLoaded) {
    return (
      <div className="loader">
        <BallTriangle
          height="100"
          width="100"
          color="grey"
          ariaLabel="loading"
        />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Компании</title>
      </Helmet>
      <Box className="headerWrapper">
        <Box className="headerTitle">Компании</Box>
      </Box>
      <Box
        sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}
      >
        <Container maxWidth={false}>
          <Alert
            severity={alert.type}
            style={{
              display: alert.isVisible ? 'flex' : 'none',
              marginBottom: 10
            }}
          >
            {alert.txt}
          </Alert>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Box sx={{ marginLeft: 2 }}>
              <Link to="/app/company/add">
                <Button color="warning" variant="contained">
                  Добавить компанию
                </Button>
              </Link>
            </Box>
          </Box>
          <Box sx={{ pt: 3 }}>
            <Card>
              <PerfectScrollbar>
                <Box sx={{ minWidth: 1500 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      mx: 2,
                      mb: 1
                    }}
                  >
                    <TextField
                      fullWidth
                      label="Название"
                      margin="normal"
                      name="name"
                      onChange={handleChangeQueryParams}
                      type="text"
                      value={queryParams.name}
                      variant="outlined"
                      style={{ marginRight: 10 }}
                    />
                    <TextField
                      fullWidth
                      label="ИНН"
                      margin="normal"
                      name="inn"
                      onChange={handleChangeQueryParams}
                      type="text"
                      value={queryParams.inn}
                      variant="outlined"
                      style={{ marginRight: 10 }}
                    />
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={handleFilterQueryParams}
                      sx={{ mt: 2, mb: 1 }}
                      style={{ minWidth: 120 }}
                    >
                      Применить
                    </Button>
                  </Box>
                  <Divider />
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ width: 80 }}>Id</TableCell>
                        <TableCell>Название</TableCell>
                        <TableCell>ИНН</TableCell>
                        <TableCell>Можно выйти?</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data?.map((item) => (
                        <TableRow hover key={item.id}>
                          <TableCell sx={{ width: 80 }}>{item?.id}</TableCell>
                          <TableCell>{item?.name || '---'}</TableCell>
                          <TableCell>{item?.inn || '---'}</TableCell>
                          <TableCell>
                            {item?.canUserLeave ? 'Да' : 'Нет' || '---'}
                          </TableCell>
                          <TableCell>
                            <Box sx={{ display: 'flex' }}>
                              <Box sx={{ display: 'flex' }}>
                                <Box sx={{ ml: 2 }}>
                                  <Link
                                    to={`/app/probe?companyId=${item.id}`}
                                    target="_blank"
                                  >
                                    <Button color="primary" variant="contained">
                                      Пробы
                                    </Button>
                                  </Link>
                                </Box>
                                <Box sx={{ ml: 2 }}>
                                  <Link
                                    to={`/app/users?companyId=${item.id}`}
                                    target="_blank"
                                  >
                                    <Button color="primary" variant="contained">
                                      Участники
                                    </Button>
                                  </Link>
                                </Box>

                                <Box sx={{ ml: 2 }}>
                                  <Link to={`/app/company/info/${item?.id}`}>
                                    <Button color="primary" variant="contained">
                                      Инфо.
                                    </Button>
                                  </Link>
                                </Box>
                                <Box sx={{ ml: 2 }}>
                                  <Link to={`/app/company/edit/${item.id}`}>
                                    <Button color="primary" variant="contained">
                                      Редакт.
                                    </Button>
                                  </Link>
                                </Box>
                                <Box sx={{ ml: 2 }}>
                                  <Button
                                    color="error"
                                    variant="contained"
                                    onClick={() => onDelete(item.id)}
                                  >
                                    Удалить
                                  </Button>
                                </Box>
                              </Box>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          labelRowsPerPage={
                            <span>Кол-во строк на странице:</span>
                          }
                          rowsPerPageOptions={[10, 20, 30, 40, 50]}
                          colSpan={7}
                          count={count}
                          page={Number(searchParams.get('page'))}
                          rowsPerPage={
                            Number(searchParams.get('limit')) === 0
                              ? 10
                              : Number(searchParams.get('limit'))
                          }
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeLimit}
                        />
                        {console.log(Number(searchParams.get('limit')))}
                      </TableRow>
                    </TableFooter>
                  </Table>
                </Box>
              </PerfectScrollbar>
            </Card>
          </Box>
        </Container>
        <ModalErrors
          isOpenModal={modalErrors}
          setIsOpenModal={setModalErrors}
        />
      </Box>
    </>
  );
};

export default CompanyList;
