import React from 'react';

function ArrowSort({rotate = 90}) {
    console.log(rotate)
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-chevron-right"
            viewBox="0 0 16 16"
            style={{
                transform: `rotate(${rotate}deg) translate(${rotate == 270 ? -2 : 2}px, ${rotate == 270 ? 5 : -5}px)`,
            }}
        >
            <path
                fillRule="evenodd"
                d="M4.646 1.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L10.293 8 4.646 2.354a.5.5 0 010-.708z"
            />
        </svg>
    );
}

export default ArrowSort;
