import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useSearchParams } from 'react-router-dom';
import {
  Box,
  Container,
  Avatar,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  TableFooter,
  TablePagination,
  TextField,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserListSkelet from '../../skeletons/UserListSkelet';
import { useDelete, useGet } from '../../API/request';
import { useConfirm } from '../../components/Confirm/index';
import { BallTriangle } from 'react-loader-spinner';
import '../../styles/All.css';

const UserList = () => {
  const confirm = useConfirm();
  const getU = useGet();
  const deleteU = useDelete();
  const [searchParams, setSearchParams] = useSearchParams();

  const [isLoaded, setIsLoaded] = useState(true);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [companies, setCompanies] = useState([]);
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);

  const [queryParams, setQueryParams] = useState({
    companyId: searchParams.get('companyId') || '',
    email: searchParams.get('email') || '',
    name: searchParams.get('name') || ''
  });

  const handleChangeQueryParams = (event) => {
    setQueryParams({
      ...queryParams,
      [event.target.name]: event.target.value
    });
  };

  const handleFilterQueryParams = () => {
    const params = {};
    if (queryParams.companyId !== '') {
      params.companyId = queryParams.companyId;
    }
    if (queryParams.name !== '') {
      params.name = queryParams.name;
    }
    if (queryParams.email !== '') {
      params.email = queryParams.email;
    }
    console.log(Object.keys(params).length !== 0);
    if (Object.keys(params).length !== 0) {
      setSearchParams(params);
    }
  };

  const loadUsers = () => {
    setIsDataLoading(true);
    setIsLoaded(true);

    let endpoint = `admin/users?page=${page + 1}&limit=${limit}`;

    if (queryParams.email !== '') {
      endpoint += `&email=${searchParams.get('email')}`;
    }
    if (queryParams.companyId !== '') {
      endpoint += `&companyId=${searchParams.get('companyId')}`;
    }
    if (queryParams.name !== '') {
      endpoint += `&name=${searchParams.get('name')}`;
    }

    getU(endpoint)
      .then((resp) => {
        if (resp.status === 'success') {
          setUsers(resp.data.users);
          setCount(resp.data.currentCount || 0);
        }
        setIsDataLoading(false);
      })
      .catch((err) => {
        console.log(err.response);
        setUsers([]);
        setCount(0);
        setIsDataLoading(false);
      })
      .finally(() => {
        setIsLoaded(false);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const onDelete = (id) => {
    confirm({
      title: 'Удаление',
      content: 'Вы уверены, что хотите удалить пользователя?',
      secondContent: 'Все пробы данного пользователя будут удалены.',
      onConfirm: () => {
        deleteU(`admin/users/${id}`)
          .then((resp) => {
            if (resp.status === 'success') {
              loadUsers();
            }
          })
          .catch((e) => {
            // console.log("opened")
            // console.log(e.response)
          });
      }
    });
  };

  useEffect(() => {
    if (queryParams.companyId === '') {
      searchParams.delete('companyId');
      setSearchParams(searchParams);
    }
    if (queryParams.name === '') {
      searchParams.delete('name');
      setSearchParams(searchParams);
    }
    if (queryParams.email === '') {
      searchParams.delete('email');
      setSearchParams(searchParams);
    }
  }, [queryParams]);

  useEffect(() => {
    loadUsers();
  }, [page, limit, searchParams]);

  useEffect(() => {
    setIsLoaded(true);
    getU(`companies?limit=all`)
      .then((resp) => {
        if (resp.status === 'success') {
          setCompanies(resp.data.companies);
        }
      })
      .catch(() => {
        showAlert('error', 'Произошла ошибка при загрузке компаний');
      })
      .finally(() => {
        setIsLoaded(false);
      });
  }, []);

  if (isLoaded) {
    return (
      <div className="loader">
        <BallTriangle
          height="100"
          width="100"
          color="grey"
          ariaLabel="loading"
        />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Пользователи</title>
      </Helmet>
      <Box className="headerWrapper">
        <Box className="headerTitle">Пользователи</Box>
      </Box>
      <Box
        sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}
      >
        <Container maxWidth={false}>
          <>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Box sx={{ marginLeft: 2 }}>
                <Link to="/app/user/add">
                  <Button color="warning" variant="contained">
                    Добавить пользователя
                  </Button>
                </Link>
              </Box>
            </Box>
            <Box sx={{ pt: 3 }}>
              <Card>
                <PerfectScrollbar>
                  <Box sx={{ minWidth: 1400 }}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mx: 2,
                        mb: 1
                      }}
                    >
                      <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
                        <InputLabel id="company">Компания</InputLabel>
                        <Select
                          labelId="Company"
                          name="companyId"
                          value={queryParams.companyId}
                          label="Компания"
                          onChange={handleChangeQueryParams}
                        >
                          {[{ id: '', name: 'Все' }, ...companies]?.map(
                            (item) => (
                              <MenuItem value={item.id}>{item.name}</MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                      <TextField
                        fullWidth
                        label="Email пользователя"
                        margin="normal"
                        name="email"
                        onChange={handleChangeQueryParams}
                        type="text"
                        value={queryParams.email}
                        variant="outlined"
                        style={{ width: '100%', marginLeft: 10 }}
                      />
                      <TextField
                        fullWidth
                        label="Имя пользователя"
                        margin="normal"
                        name="name"
                        onChange={handleChangeQueryParams}
                        type="text"
                        value={queryParams.name}
                        variant="outlined"
                        style={{ width: '100%', marginLeft: 10 }}
                      />

                      <Button
                        color="primary"
                        variant="contained"
                        onClick={handleFilterQueryParams}
                        sx={{ mt: 2, mb: 1 }}
                        style={{ minWidth: 110, marginLeft: 10 }}
                      >
                        Применить
                      </Button>
                    </Box>
                    <Divider />
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ width: 80 }}>Id</TableCell>
                          <TableCell>Аватар</TableCell>
                          <TableCell>Имя</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell>Компания</TableCell>
                          <TableCell>Роль</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {users?.map((user) => (
                          <TableRow hover key={user.id}>
                            <TableCell sx={{ width: 80 }}>{user?.id}</TableCell>
                            <TableCell>
                              <Box
                                sx={{ alignItems: 'center', display: 'flex' }}
                              >
                                <Avatar
                                  src={
                                    user.avatar
                                      ? `${process.env.REACT_APP_API_URL}/uploads/avatars/${user.avatar}`
                                      : ''
                                  }
                                />
                              </Box>
                            </TableCell>

                            <TableCell>{user?.name || '---'}</TableCell>
                            <TableCell>{user?.email || '---'}</TableCell>
                            <TableCell>
                              {user?.company?.name ? (
                                <Link to={`/app/company/info/${user.company.id}`} target="_blank">
                                  {user?.company?.name}
                                </Link>
                              ) : (
                                '---'
                              )}
                            </TableCell>
                            <TableCell>{user?.role || '---'}</TableCell>
                            <TableCell>
                              <Box sx={{ display: 'flex' }}>
                              <Box sx={{ ml: 2 }}>
                                  <Link
                                    to={`/app/probe?userId=${user.id}`}
                                    target="_blank"
                                  >
                                    <Button color="primary" variant="contained">
                                      Пробы
                                    </Button>
                                  </Link>
                                </Box>
                                <Box sx={{ display: 'flex', ml: 2 }}>
                                  <Link to={`/app/user/${user.id}`}>
                                    <Button color="primary" variant="contained">
                                      Инфо.
                                    </Button>
                                  </Link>
                                  <Box sx={{ ml: 2 }}>
                                    <Link
                                      to={`/app/user/edit/${user.id}`}
                                    >
                                      <Button
                                        color="primary"
                                        variant="contained"
                                      >
                                        Редакт.
                                      </Button>
                                    </Link>
                                  </Box>
                                  <Box sx={{ ml: 2 }}>
                                    <Button
                                      color="error"
                                      variant="contained"
                                      onClick={() => onDelete(user.id)}
                                    >
                                      Удалить
                                    </Button>
                                  </Box>
                                </Box>
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            labelRowsPerPage={
                              <span>Кол-во строк на странице:</span>
                            }
                            rowsPerPageOptions={[10, 20, 30, 40, 50]}
                            colSpan={7}
                            count={count}
                            rowsPerPage={limit}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeLimit}
                          />
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </Box>
                </PerfectScrollbar>
              </Card>
            </Box>
          </>
        </Container>
      </Box>
    </>
  );
};

export default UserList;
