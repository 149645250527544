import { Navigate } from 'react-router-dom';
import DashboardLayout from './layouts/DashboardLayout';
import MainLayout from './layouts/MainLayout';
import Account from './pages/Account/Account';
import UserInfo from './pages/User/UserInfo';
import UserEdit from './pages/User/UserEdit';
import UserList from './pages/User/UserList';
import Login from './pages/Login/Login';
import NotFound from './pages/NotFound/NotFound';
import Logs from './pages/Systems/Logs/Logs';
import Dashboard from './pages/Dashboard/Dashboard';
import EmailTemplateList from './pages/Templates/Email-Templates/EmailTemplateList';
import EmailTemplateCreate from './pages/Templates/Email-Templates/EmailTemplateCreate';
import EmailTemplateEdit from './pages/Templates/Email-Templates/EmailTemplateEdit';
import CommonSettings from './pages/Systems/CommonSettings/CommonSettings';
import UpdateSystem from './pages/Systems/UpdateSystem/UpdateSystem';
import UserAdd from './pages/User/UserAdd';
import EmailHistoryList from './pages/Templates/Email-Hstory/EmailHistoryList';
import FeedBackList from './pages/FeedBacks/FeedBackList';
import FeedBackEdit from './pages/FeedBacks/FeedBackEdit';
import CompanyList from './pages/Company/CompanyList';
import CompanyAdd from './pages/Company/CompanyAdd';
import CompanyInfo from './pages/Company/CompanyInfo';
import CompanyEdit from './pages/Company/CompanyEdit';
import AppFluer from './pages/Settings/AppFluer/AppFluer';
import AppLogs from './pages/AppWork/AppLogs';
import DocumentEdit from './pages/Documents/DocumentEdit';
import DocumentAdd from './pages/Documents/DocumentAdd';
import DocumentsList from './pages/Documents/DocumentsList';
import CompanyEmailsList from './pages/Company/CompanyEmailsList';
import ProbesList from './pages/Probe/ProbeList';
import ProbeInfo from './pages/Probe/ProbeInfo';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'account', element: <Account /> },
      { path: 'dashboard', element: <Dashboard /> },

      { path: 'user/:id', element: <UserInfo /> },
      { path: 'user/edit/:id', element: <UserEdit /> },
      { path: 'user/add', element: <UserAdd /> },
      { path: 'users', element: <UserList /> },

      { path: 'company', element: <CompanyList /> },
      { path: 'company/emails/:id', element: <CompanyEmailsList /> },
      { path: 'company/info/:id', element: <CompanyInfo /> },
      { path: 'company/add', element: <CompanyAdd /> },
      { path: 'company/edit/:id', element: <CompanyEdit /> },

      { path: 'probe', element: <ProbesList /> },
      { path: 'probe/info/:id', element: <ProbeInfo /> },

      { path: 'feedbacks/edit/:id', element: <FeedBackEdit /> },
      { path: 'feedbacks', element: <FeedBackList /> },

      { path: 'email-templates', element: <EmailTemplateList /> },
      { path: 'email-history', element: <EmailHistoryList /> },
      { path: 'email-templates/create', element: <EmailTemplateCreate /> },
      { path: 'email-templates/edit/:id', element: <EmailTemplateEdit /> },

      { path: 'documents/edit/:id', element: <DocumentEdit /> },
      { path: 'documents/add', element: <DocumentAdd /> },
      { path: 'documents', element: <DocumentsList /> },

      { path: 'app-logs', element: <AppLogs /> },

      { path: 'logs', element: <Logs /> },
      { path: 'common-settings', element: <CommonSettings /> },
      { path: 'update-system', element: <UpdateSystem /> },

      { path: 'app-fluer', element: <AppFluer /> },

      { path: '', element: <Navigate to="/app/dashboard" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/app/dashboard" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
