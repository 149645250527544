import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Avatar,
  Container,
  Button,
  Card,
  CardContent,
  Divider,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  Typography,
  Alert,
  TextField,
  TableFooter,
  TablePagination,
  CardActionArea,
  CardMedia,
  CardHeader
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { Link, useParams, useNavigate, Navigate } from 'react-router-dom';
import { useDelete, useGet } from '../../API/request';
import '../../styles/All.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { BallTriangle } from 'react-loader-spinner';
import { useConfirm } from '../../components/Confirm';

const ProbeInfo = () => {
  const navigate = useNavigate();
  const confirm = useConfirm();
  const getU = useGet();
  const deleteU = useDelete();
  const { id } = useParams();

  const [isLoaded, setIsLoaded] = useState(true);

  const [data, setData] = useState({});
  const [linkedProbes, setLinkedProbes] = useState([]);
  const [changeHistory, setChangeHistory] = useState([]);
  const [changeHistoryCount, setChangeHistoryCount] = useState([]);
  const [changeHistoryLimit, setChangeHistoryLimit] = useState(10);
  const [changeHistoryPage, setChangeHistoryPage] = useState(0);
  const [handlingProbes, setHandlingProbes] = useState([]);
  const [handlingProbesCount, setHandlingProbesCount] = useState([]);
  const [handlingProbesLimit, setHandlingProbesLimit] = useState(10);
  const [handlingProbesPage, setHandlingProbesPage] = useState(0);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const [alert, setAlert] = useState({
    txt: '',
    isVisible: false,
    type: 'error'
  });

  const showAlert = (type, text) => {
    setAlert({
      txt: text,
      type,
      isVisible: true
    });

    setTimeout(() => {
      setAlert({
        txt: text,
        type,
        isVisible: false
      });
    }, 2500);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const handleChangePageChangeHistory = (event, newPage) => {
    setChangeHistoryCount(newPage);
  };

  const handleChangeLimitChangeHistory = (event) => {
    setChangeHistoryLimit(event.target.value);
    setChangeHistoryCount(0);
  };

  const handleChangePageHandlingProbe = (event, newPage) => {
    setHandlingProbesPage(newPage);
  };

  const handleChangeLimitHandlingProbe = (event) => {
    setHandlingProbesLimit(event.target.value);
    setHandlingProbesPage(0);
  };

  const onDelete = (id) => {
    confirm({
      title: 'Удаление',
      content: 'Вы уверены, что хотите отвязать пробу?',
      onConfirm: () => {
        deleteU(`probes/linked/${id}`)
          .then((resp) => {
            if (resp.status === 'success') {
              loadLinkedProbes();
            } else {
              showAlert('error', 'Произошла ошибка при попытке отвязать пробу');
            }
          })
          .catch((e) => {
            showAlert('error', e.response.data.message);
          });
      }
    });
  };

  useEffect(() => {
    setIsLoaded(true);
    getU(`probes/${id}`)
      .then((resp) => {
        if (resp.status === 'success') {
          setData(resp.data.probe);
        } else {
          showAlert('error', 'Произошла ошибка при попытке получить пробу');
        }
      })
      .catch(() => {
        showAlert('error', 'Произошла ошибка при попытке получить пробу');
      })
      .finally(() => {
        setIsLoaded(false);
      });

    loadLinkedProbes();
    loadHandlingHistory();
    loadChangeHistory();
  }, [id]);

  const loadLinkedProbes = () => {
    setIsLoaded(true);

    let endpoint = `probes/linked/${id}?page=${page + 1}&limit=${limit}`;

    getU(endpoint)
      .then((resp) => {
        if (resp.status === 'success') {
          setLinkedProbes(resp.data.probes);
          setCount(resp.data.currentCount || 0);
        }
      })
      .catch((err) => {
        console.log(err.response);
        setLinkedProbes([]);
        setCount(0);
      })
      .finally(() => {
        setIsLoaded(false);
      });
  };

  const loadChangeHistory = () => {
    setIsLoaded(true);

    let endpoint = `probes/history/changes/${id}?page=${
      page + 1
    }&limit=${limit}`;

    getU(endpoint)
      .then((resp) => {
        if (resp.status === 'success') {
          setChangeHistory(resp.data.probeHistory);
          setChangeHistoryCount(resp.data.currentCount || 0);
        }
      })
      .catch((err) => {
        console.log(err.response);
        setChangeHistory([]);
        setChangeHistoryCount(0);
      })
      .finally(() => {
        setIsLoaded(false);
      });
  };

  const loadHandlingHistory = () => {
    setIsLoaded(true);

    let endpoint = `probes/history/handling/${id}?page=${
      page + 1
    }&limit=${limit}`;

    getU(endpoint)
      .then((resp) => {
        if (resp.status === 'success') {
          setHandlingProbes(resp.data.probeHistory);
          setHandlingProbesCount(resp.data.currentCount || 0);
        }
      })
      .catch((err) => {
        console.log(err.response);
        setHandlingProbes([]);
        setHandlingProbesCount(0);
      })
      .finally(() => {
        setIsLoaded(false);
      });
  };

  function historyStringConverter(item) {
    if (item === 'code'){
        return 'Шифр'
    } else if(item === 'vendorCode'){
        return 'Артикул'
    } else if(item === 'application'){
        return 'Заявка'
    } else if(item === 'primaryMarking'){
        return 'Первичная маркировка'
    } else if(item === 'object'){
        return 'Объект'
    } else if(item === 'note'){
        return 'Примечание'
    } else if(item === 'objectCharacteristics'){
        return 'Характеристика объекта'
    } else if(item === 'acceptanceDate'){
        return 'Дата приемки'
    } else if(item === 'selectionDate'){
        return 'Дата отбора'
    } else {
        return item
    }
}

  if (isLoaded) {
    return (
      <div className="loader">
        <BallTriangle
          height="100"
          width="100"
          color="grey"
          ariaLabel="loading"
        />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Проба</title>
      </Helmet>
      <Box className="headerWrapper">
        <Box className="headerTitle">
          <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
            Назад
          </Button>
          {data?.code}
        </Box>
      </Box>

      <Box sx={{ backgroundColor: 'background.default', pt: 3, pb: 1 }}>
        <Container maxWidth={false}>
          <Alert
            severity={alert.type}
            style={{ display: alert.isVisible ? 'flex' : 'none' }}
          >
            {alert.txt}
          </Alert>
          <Card>
            <CardContent sx={{ p: 3 }}>
              <PerfectScrollbar>
                <div className="wrapAvatar">
                  <div className="info-block">
                    <div className="wrap">
                      <div className="label">ID:</div>
                      <div className="text">{data?.id || '---'}</div>
                    </div>

                    <div className="wrap">
                      <div className="label">Шифр</div>
                      <div className="text">{data?.code || '---'}</div>
                    </div>

                    <div className="wrap">
                      <div className="label">Объект:</div>
                      <div className="text">{data?.object || '---'}</div>
                    </div>

                    <div className="wrap">
                      <div className="label">
                        Характеристика
                        <br />
                        объекта:
                      </div>
                      <div className="text">
                        {data?.objectCharacteristics || '---'}
                      </div>
                    </div>

                    <div className="wrap">
                      <div className="label">Артикул:</div>
                      <div className="text">{data?.vendorCode || '---'}</div>
                    </div>

                    <div className="wrap">
                      <div className="label">Заявка:</div>
                      <div className="text">{data?.application || '---'}</div>
                    </div>

                    <div className="wrap">
                      <div className="label">Дата приемки:</div>
                      <div className="text">
                        {data?.acceptanceDate
                          ? new Date(data?.acceptanceDate).toLocaleString()
                          : '---'}
                      </div>
                    </div>

                    <div className="wrap">
                      <div className="label">Дата отбора:</div>
                      <div className="text">
                        {data?.selectionDate
                          ? new Date(data?.selectionDate).toLocaleString()
                          : '---'}
                      </div>
                    </div>

                    <div className="wrap">
                      <div className="label">
                        Первичная <br />
                        маркировка:
                      </div>
                      <div className="text">
                        {data?.primaryMarking || '---'}
                      </div>
                    </div>

                    <div className="wrap">
                      <div className="label">Тип обращения:</div>
                      <div className="text">{data?.handlingType || '---'}</div>
                    </div>

                    <div className="wrap">
                      <div className="label">Дата обращения:</div>
                      <div className="text">
                        {data?.handlingDate
                          ? new Date(data?.handlingDate).toLocaleString()
                          : '---'}
                      </div>
                    </div>

                    <div className="wrap">
                      <div className="label">Дата утилизации:</div>
                      <div className="text">
                        {data?.utilizationDate
                          ? new Date(data?.utilizationDate).toLocaleString()
                          : '---'}
                      </div>
                    </div>

                    <div className="wrap">
                      <div className="label">Компания:</div>
                      <div className="text">
                        {data?.company?.name ? (
                          <Link
                            to={`/app/company/info/${data.company.id}`}
                            target="_blank"
                          >
                            {data?.company?.name}
                          </Link>
                        ) : (
                          '---'
                        )}
                      </div>
                    </div>

                    <div className="wrap">
                      <div className="label">Пользователь:</div>
                      <div className="text">
                        {data?.user?.email ? (
                          <Link
                            to={`/app/user/${data.user.id}`}
                            target="_blank"
                          >
                            {data?.user?.email}
                          </Link>
                        ) : (
                          '---'
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </PerfectScrollbar>
            </CardContent>
          </Card>

          <div
            className="label"
            style={{
              marginTop: 30,
              fontSize: 20
            }}
          >
            История изменений
          </div>
          <Box sx={{ pt: 3 }}>
            <Card>
              <PerfectScrollbar>
                <Box>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ width: 80 }}>Id</TableCell>
                        <TableCell>Изменения</TableCell>
                        <TableCell>Пользователь</TableCell>
                        <TableCell>Дата</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {changeHistory?.map((item) => {
                        return (
                          <TableRow hover key={item.id}>
                            <TableCell sx={{ width: 80 }}>{item?.id}</TableCell>
                            <TableCell sx={{ width: 300 }}>
                              {item?.fields?.map((field) => (
                                <div>
                                  <div>
                                    <b>{historyStringConverter(field.field)}:</b>
                                  </div>
                                  <div>До: {field.old || "---"}</div>
                                  <div>После: {field.new  || "---"}</div>
                                </div>
                              )) || "---"}
                            </TableCell>
                            <TableCell>
                              {item?.user?.email ? (
                                <Link
                                  to={`/app/user/${item.user.id}`}
                                  target="_blank"
                                >
                                  {item?.user?.email}
                                </Link>
                              ) : (
                                '---'
                              )}
                            </TableCell>
                            <TableCell>
                              {item?.creationDate
                                ? new Date(item?.creationDate).toLocaleString()
                                : '---'}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          labelRowsPerPage={
                            <span>Кол-во строк на странице:</span>
                          }
                          rowsPerPageOptions={[10, 20, 30, 40, 50]}
                          colSpan={7}
                          count={count}
                          page={page}
                          rowsPerPage={limit}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeLimit}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </Box>
              </PerfectScrollbar>
            </Card>
          </Box>

          <div
            className="label"
            style={{
              marginTop: 30,
              fontSize: 20
            }}
          >
            Связанные пробы
          </div>
          <Box sx={{ pt: 3 }}>
            <Card>
              <PerfectScrollbar>
                <Box>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ width: 80 }}>Id</TableCell>
                        <TableCell>Шифр</TableCell>
                        <TableCell>Объект</TableCell>
                        <TableCell>Характеристика объекта</TableCell>
                        <TableCell>Артикул</TableCell>
                        <TableCell>Дата приемки</TableCell>
                        <TableCell>Дата утилизации</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {linkedProbes?.map((item) => (
                        <TableRow hover key={item.id}>
                          <TableCell sx={{ width: 80 }}>{item?.id}</TableCell>
                          <TableCell>{item?.code || '---'}</TableCell>
                          <TableCell>{item?.object || '---'}</TableCell>
                          <TableCell>
                            {item?.objectCharacteristics || '---'}
                          </TableCell>
                          <TableCell>{item?.vendorCode || '---'}</TableCell>
                          <TableCell>
                            {item?.acceptanceDate
                              ? new Date(item?.acceptanceDate).toLocaleString()
                              : '---'}
                          </TableCell>
                          <TableCell>
                            {item?.utilizationDate
                              ? new Date(item?.utilizationDate).toLocaleString()
                              : '---'}
                          </TableCell>
                          <TableCell>
                            <Box sx={{ display: 'flex' }}>
                              <Box sx={{ display: 'flex' }}>
                                <Box sx={{ ml: 2 }}>
                                  <Link to={`/app/probe/info/${item?.id}`}>
                                    <Button color="primary" variant="contained">
                                      Инфо.
                                    </Button>
                                  </Link>
                                </Box>
                                <Box sx={{ ml: 2 }}>
                                  <Button
                                    color="error"
                                    variant="contained"
                                    onClick={() => onDelete(item.id)}
                                  >
                                    Отвязать
                                  </Button>
                                </Box>
                              </Box>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          labelRowsPerPage={
                            <span>Кол-во строк на странице:</span>
                          }
                          rowsPerPageOptions={[10, 20, 30, 40, 50]}
                          colSpan={7}
                          count={changeHistoryCount}
                          page={changeHistoryPage}
                          rowsPerPage={changeHistoryPage}
                          onPageChange={handleChangePageChangeHistory}
                          onRowsPerPageChange={handleChangeLimitChangeHistory}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </Box>
              </PerfectScrollbar>
            </Card>
          </Box>


          <div
            className="label"
            style={{
              marginTop: 30,
              fontSize: 20
            }}
          >
            История обращения
          </div>
          <Box sx={{ pt: 3 }}>
            <Card>
              <PerfectScrollbar>
                <Box>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ width: 80 }}>Id</TableCell>
                        <TableCell>Изменения</TableCell>
                        <TableCell>Пользователь</TableCell>
                        <TableCell>Дата</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {handlingProbes?.map((item) => {
                        return (
                          <TableRow hover key={item.id}>
                            <TableCell sx={{ width: 80 }}>{item?.id}</TableCell>
                            <TableCell sx={{ width: 300 }}>
                              {item?.fields?.map((field) => (
                                <div>
                                  <div>
                                    <b>{historyStringConverter(field.field)}:</b>
                                  </div>
                                  <div>До: {field.old || "---"}</div>
                                  <div>После: {field.new  || "---"}</div>
                                </div>
                              )) || "---"}
                            </TableCell>
                            <TableCell>
                              {item?.user?.email ? (
                                <Link
                                  to={`/app/user/${item.user.id}`}
                                  target="_blank"
                                >
                                  {item?.user?.email}
                                </Link>
                              ) : (
                                '---'
                              )}
                            </TableCell>
                            <TableCell>
                              {item?.creationDate
                                ? new Date(item?.creationDate).toLocaleString()
                                : '---'}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          labelRowsPerPage={
                            <span>Кол-во строк на странице:</span>
                          }
                          rowsPerPageOptions={[10, 20, 30, 40, 50]}
                          colSpan={7}
                          count={handlingProbesCount}
                          page={handlingProbesPage}
                          rowsPerPage={handlingProbesLimit}
                          onPageChange={handleChangePageHandlingProbe}
                          onRowsPerPageChange={handleChangeLimitHandlingProbe}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </Box>
              </PerfectScrollbar>
            </Card>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default ProbeInfo;
