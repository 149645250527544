import { Helmet } from 'react-helmet';
import {
    Box,
    Container,
    Button, TextField, CardContent
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { useNavigate, useParams } from 'react-router-dom';
import { useGet, usePost, usePut } from '../../API/request';
import React, { useEffect, useState } from 'react';
import { BallTriangle } from 'react-loader-spinner';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import Alert from '@material-ui/core/Alert';
import { EditorState, convertToRaw, ContentState, convertFromHTML  } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const CompanyEdit = () => {

    const {id} = useParams();
    const navigate = useNavigate();
    const postU = usePost();
    const getU = useGet();
    const putU = usePut();

    const [isLoaded, setIsLoaded] = useState(true);

    const [title, setTitle] = useState('')

    const [values, setValues] = useState({
        name: '',
        inn: '',
        accessCode: '',
        canUserLeave: true,
    });

    const [errors, setErrors] = useState({
        name: false,
        inn: false,
        accessCode: false,
        canUserLeave: false,
    });

    const [isValueEdit, setIsValueEdit] = useState({
        name: false,
        inn: false,
        accessCode: false,
        canUserLeave: false,
    });

    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [nothingChanged, setNothingChanged] = useState(true);

    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });

            setSubmitDisabled(false);
        }, 1400);
    };


    const handleChange = (event) => {
        setNothingChanged(false);
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
        setErrors({
            ...errors,
            [event.target.name]: false
        });
        setIsValueEdit({
            ...isValueEdit,
            [event.target.name]: true
        });
    };

    const validate = () => {
        let validComplete = true;
        let formErrors = { ...errors };

        if (values.name === '') {
            validComplete = false;
            formErrors.name = false;
            showAlert('error', 'Поле Название не должно быть пустым');
        }
        if (values.inn === '') {
            validComplete = false;
            formErrors.type = false;
            showAlert('error', 'Поле ИНН не должно быть пустым');
        }
        if (values.inn?.length  < 10) {
            validComplete = false;
            formErrors.type = false;
            showAlert('error', 'Поле ИНН должно быть длинее 10 символов');
        }

        if (values.accessCode === '') {
            validComplete = false;
            formErrors.type = false;
            showAlert('error', 'Поле Код доступа не должно быть пустым');
        }

        setErrors(formErrors);
        return validComplete;
    };


    const submit = async () => {
        if (nothingChanged) {
            showAlert('error', 'Нет изменений');
            return;
        }

        if (validate()) {
            setSubmitDisabled(true);

            const data = {
                ...values
            };

            putU(`companies/${id}`, data)
                .then((resp) => {
                    if (resp.status === 'success') {
                        showAlert('success', 'Вы успешно обновили компанию');
                    } else {
                        showAlert('error', 'Произошла ошибка при обновлении компании');
                    }
                })
                .catch((err) => {
                    showAlert('error', err.response.data.message);
                })
                .finally(() => {
                    setSubmitDisabled(false);
                });
        }
    };

    const getLists = async () => {
        setIsLoaded(true);

        await getU(`companies/${id}`)
            .then((resp) => {
                if (resp.status === 'success') {
                    const data = resp.data.company;
                    setValues({
                        name: data?.name || '',
                        accessCode: data?.accessCode || '',
                        inn: data?.inn || '',
                        canUserLeave: Boolean(data?.canUserLeave || ''),
                    })

                    setTitle(data?.name)
                } else {
                    showAlert('error', 'Произошла ошибка при попытке получить компанию');
                }
            })
            .catch(() => {
                showAlert('error', 'Произошла ошибка при попытке получить компанию');
            })
            .finally(() => {
                    setIsLoaded(false)
            });
    };

    useEffect(() => {
        getLists();
    }, []);

    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>

            <Helmet>
                <title>Компания</title>
            </Helmet>
            <Box className="headerWrapper">
                <Box className="headerTitle">
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate(-1)}>
                        Назад
                    </Button>
                    {title}
                </Box>
                <ul className="headerList">
                    <li onClick={() => navigate(-1)}>Компании</li>
                    <li>/</li>
                    <li>{title}</li>
                </ul>
            </Box>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
                <Container maxWidth={false}>
                    <Box sx={{ pt: 2 }}>
                        <form>
                            <Card>
                                <CardHeader
                                    title="Редактирование компании"
                                />
                                <Divider/>
                                <CardContent sx={{ position: 'relative' }}>
                                    <TextField
                                        fullWidth
                                        label="Название"
                                        margin="normal"
                                        name="name"
                                        onChange={handleChange}
                                        type="text"
                                        value={values.name}
                                        variant="outlined"
                                        error={errors.name}
                                    />
                                    <TextField
                                        fullWidth
                                        label="ИНН"
                                        margin="normal"
                                        name="inn"
                                        onChange={handleChange}
                                        type="text"
                                        value={values.inn}
                                        variant="outlined"
                                        error={errors.inn}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Код доступа"
                                        margin="normal"
                                        name="accessCode"
                                        onChange={handleChange}
                                        type="text"
                                        value={values.accessCode}
                                        variant="outlined"
                                        error={errors.accessCode}
                                    />
                                    <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
                                        <InputLabel id="canUserLeave">
                                        Можно выйти?
                                        </InputLabel>
                                        <Select
                                            labelId="canUserLeave"
                                            name="canUserLeave"
                                            value={values.canUserLeave}
                                            label="Можно выйти?"
                                            onChange={handleChange}
                                        >
                                            <MenuItem value={true}>Да</MenuItem>
                                            <MenuItem value={false}>Нет</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <Alert severity={alert.type}
                                           style={{ display: alert.isVisible ? 'flex' : 'none' }}>
                                        {alert.txt}
                                    </Alert>
                                </CardContent>
                                <Divider/>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={submit}
                                        disabled={submitDisabled}
                                    >
                                        Сохранить
                                    </Button>
                                </Box>
                            </Card>
                        </form>
                    </Box>
                    
                </Container>
            </Box>
        </>
    );
};

export default CompanyEdit;
