import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Avatar,
  Container,
  Button,
  Card,
  CardContent,
  Divider,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  Typography,
  Alert,
  TextField,
  TableFooter,
  TablePagination,
  CardActionArea,
  CardMedia
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { Link, useParams, useNavigate, Navigate } from 'react-router-dom';
import { useGet } from '../../API/request';
import '../../styles/All.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { BallTriangle } from 'react-loader-spinner';
import DishInfoList from '../../components/DishInfoList';
import { apiUrl } from '../../API/environment';

const CompanyInfo = () => {
  const navigate = useNavigate();
  const getU = useGet();
  const { id } = useParams();

  const [isLoaded, setIsLoaded] = useState(true);

  const [data, setData] = useState({});

  const [alert, setAlert] = useState({
    txt: '',
    isVisible: false,
    type: 'error'
  });

  const showAlert = (type, text) => {
    setAlert({
      txt: text,
      type,
      isVisible: true
    });

    setTimeout(() => {
      setAlert({
        txt: text,
        type,
        isVisible: false
      });
    }, 2500);
  };

  useEffect(() => {
    setIsLoaded(true);
    getU(`companies/${id}`)
      .then((resp) => {
        if (resp.status === 'success') {
          setData(resp.data.company);
        } else {
          showAlert('error', 'Произошла ошибка при попытке получить компанию');
        }
      })
      .catch(() => {
        showAlert('error', 'Произошла ошибка при попытке получить компанию');
      })
      .finally(() => {
        setIsLoaded(false);
      });
  }, [id]);

  if (isLoaded) {
    return (
      <div className="loader">
        <BallTriangle
          height="100"
          width="100"
          color="grey"
          ariaLabel="loading"
        />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Компания</title>
      </Helmet>
      <Box className="headerWrapper">
        <Box className="headerTitle">
          <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
            Назад
          </Button>
          {data?.name}
        </Box>
        <ul className="headerList">
          <li onClick={() => navigate(-1)}>Компании</li>
          <li>/</li>
          <li>{data?.name}</li>
        </ul>
      </Box>

      <Box sx={{ backgroundColor: 'background.default', pt: 3, pb: 1 }}>
        <Container maxWidth={false}>
          <Alert
            severity={alert.type}
            style={{ display: alert.isVisible ? 'flex' : 'none' }}
          >
            {alert.txt}
          </Alert>
          <Card>
            <CardContent sx={{ p: 3 }}>
              <PerfectScrollbar>
                <div className="wrapAvatar">
                  <div className="info-block">
                    <div className="wrap">
                      <div className="label">ID:</div>
                      <div className="text">{data?.id || '---'}</div>
                    </div>

                    <div className="wrap">
                      <div className="label">Название</div>
                      <div className="text">{data?.name || '---'}</div>
                    </div>

                    <div className="wrap">
                      <div className="label">ИНН:</div>
                      <div className="text">{data?.inn || '---'}</div>
                    </div>

                    <div className="wrap">
                      <div className="label">Код доступа:</div>
                      <div className="text">{data?.accessCode || '---'}</div>
                    </div>
                    <div className="wrap">
                      <div className="label">Можно выйти?:</div>
                      <div className="text">
                        {data?.canUserLeave ? 'Да' : 'Нет' || '---'}
                      </div>
                    </div>
                  </div>
                </div>
                <Box style={{
                  display: "flex",
                  justifyContent: "space-between"
                }}>
                  <Link to={`/app/company/emails/${data?.id}`}>
                    <Button color="primary" variant="contained">
                      Email`ы
                    </Button>
                  </Link>

                  <a href={`${apiUrl}/api/probes/xlsx?companyId=${data?.id}`}>
                    <Button color="primary" variant="contained">
                      Выгрузка проб
                    </Button>
                  </a>
                </Box>
              </PerfectScrollbar>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default CompanyInfo;
