import { Helmet } from 'react-helmet';
import { Box, Container, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useGet } from '../../API/request';
import { BallTriangle } from 'react-loader-spinner';
import StatisticsItem from '../../components/dashboard/StatisticsItem';

const Dashboard = () => {
  const [loaded, setLoaded] = useState(true);
  const [isLoaded, setIsLoaded] = useState(true);

  const getU = useGet();

  const [data, setData] = useState({
    date: 0
  });

  const [usersCount, setUsersCount] = useState(0);
  const [companiesCount, setCompaniesCount] = useState(0);
  const [feedbackCount, setFeedbackCount] = useState(0);

  const loadUsers = () => {
    setIsLoaded(true);

    let endpoint = `admin/users?page=1&limit=10?page=1&limit=1`;

    getU(endpoint)
      .then((resp) => {
        if (resp.status === 'success') {
          setUsersCount(resp.data.totalCount || 0);
        } else {
          showAlert(
            'error',
            'Произошла ошибка при получении пользователей, повторите попытку позже'
          );
        }
      })
      .catch((err) => {
        console.log(err.response);
        setUsersCount(0);
      })
      .finally(() => {
        setIsLoaded(false);
      });
  };

  const loadCompanies = () => {
    setIsLoaded(true);

    let endpoint = `companies?page=1&limit=1`;

    getU(endpoint)
      .then((resp) => {
        if (resp.status === 'success') {
          setCompaniesCount(resp.data.totalCount || 0);
        } else {
          showAlert(
            'error',
            'Произошла ошибка при получении компаний, повторите попытку позже'
          );
        }
      })
      .catch((err) => {
        console.log(err.response);
        setCompaniesCount(0);
      })
      .finally(() => {
        setIsLoaded(false);
      });
  };

  const loadFeedback = () => {
    setIsLoaded(true);

    let endpoint = `feedback?page=1&limit=1&isChecked=0`;

    getU(endpoint)
      .then((resp) => {
        if (resp.status === 'success') {
          setFeedbackCount(resp.data.currentCount || 0);
        } else {
          showAlert(
            'error',
            'Произошла ошибка при получении пользователей, повторите попытку позже'
          );
        }
      })
      .catch((err) => {
        console.log(err.response);
        setFeedbackCount(0);
      })
      .finally(() => {
        setIsLoaded(false);
      });
  };

  useEffect(() => {
    loadUsers();
    loadFeedback();
    loadCompanies();
  }, []);

  if (isLoaded) {
    return (
      <div className="loader">
        <BallTriangle
          height="100"
          width="100"
          color="grey"
          ariaLabel="loading"
        />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <Box className="headerWrapper">
        <Box className="headerTitle">Статистика</Box>
      </Box>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Grid container spacing={3}>
            <Grid item lg={3} sm={6} xl={4} xs={12}>
              <StatisticsItem
                name="Количество пользователей"
                value={usersCount}
              />
            </Grid>
            <Grid item lg={3} sm={6} xl={4} xs={12}>
              <StatisticsItem
                name="Количество компаний"
                value={companiesCount}
              />
            </Grid>
            <Grid item lg={3} sm={6} xl={4} xs={12}>
              <StatisticsItem
                name="Количество необработанных заявок"
                value={feedbackCount}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Dashboard;
