import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useSearchParams } from 'react-router-dom';
import {
  Box,
  Container,
  Avatar,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  TableFooter,
  TablePagination,
  TextField,
  Divider
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserListSkelet from '../../skeletons/UserListSkelet';
import { useDelete, useGet } from '../../API/request';
import { useConfirm } from '../../components/Confirm/index';
import { BallTriangle } from 'react-loader-spinner';
import '../../styles/All.css';
import ArrowSort from '../../icons/ArrowSort';

const FeedBackList = () => {
  const confirm = useConfirm();
  const getU = useGet();
  const deleteU = useDelete();

  const [isLoaded, setIsLoaded] = useState(true);
  const [feedBacks, setFeedBacks] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);

  const [searchParams, setSearchParams] = useSearchParams({
    sort: 'id',
    order: 'desc'
  });

  const [queryParams, setQueryParams] = useState({
    companyId: searchParams.get('companyId') || '',
    userId: searchParams.get('userId') || '',
    page: searchParams.get('page') || 0,
    limit: searchParams.get('limit') || 10,
    sort: searchParams.get('sort') || 'id',
    order: searchParams.get('order') || 'desc'
  });

  const loadUsers = () => {
    setIsLoaded(true);

    let endpoint = `feedback?page=${page + 1}&limit=${limit}&sort=${
        searchParams.get('sort') || 'id'
      }&order=${searchParams.get('order') || 'desc'}`;

    getU(endpoint)
      .then((resp) => {
        console.log(resp.data);
        if (resp.status === 'success') {
          setFeedBacks(resp.data.feedbacks);
          setCount(resp.data.currentCount || 0);
        }
      })
      .catch((err) => {
        console.log(err.response);
        setFeedBacks([]);
        setCount(0);
      })
      .finally(() => {
        setIsLoaded(false);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (queryParams.userId !== '') {
      setSearchParams({
        userId: queryParams.userId,
        page: newPage,
        limit:
          Number(searchParams.get('limit')) === 0
            ? 10
            : Number(searchParams.get('limit')),
        sort: searchParams.get('sort'),
        order: searchParams.get('order')
      });
    } else if (queryParams.companyId !== '') {
      setSearchParams({
        companyId: queryParams.companyId,
        page: newPage,
        limit:
          Number(searchParams.get('limit')) === 0
            ? 10
            : Number(searchParams.get('limit')),
        sort: searchParams.get('sort'),
        order: searchParams.get('order')
      });
    } else {
      setSearchParams({
        page: newPage,
        limit:
          Number(searchParams.get('limit')) === 0
            ? 10
            : Number(searchParams.get('limit')),
        sort: searchParams.get('sort'),
        order: searchParams.get('order')
      });
    }
  };

  const handleChangeLimit = (event) => {
    setLimit(event.target.value);
    setPage(0);
    if (queryParams.userId !== '') {
      setSearchParams({
        userId: queryParams.userId,
        page: 1,
        limit: event.target.value,
        sort: searchParams.get('sort'),
        order: searchParams.get('order')
      });
    } else if (queryParams.companyId !== '') {
      setSearchParams({
        companyId: queryParams.companyId,
        page: 0,
        limit: event.target.value,
        sort: searchParams.get('sort'),
        order: searchParams.get('order')
      });
    } else {
      setSearchParams({
        page: 0,
        limit: event.target.value,
        sort: searchParams.get('sort'),
        order: searchParams.get('order')
      });
    }
  };

  const handleChangeSort = (sort) => {
    console.log(sort);
    setSearchParams({ ...searchParams, sort });

    let order = searchParams.get('order');

    if (sort !== searchParams.get('sort')) order = 'desc';
    else order = 'desc' === order ? 'asc' : 'desc';

    setSearchParams({ ...searchParams, sort, order });
  };

  const onDelete = (id) => {
    confirm({
      title: 'Удаление',
      content: 'Вы уверены, что хотите удалить пользователя?',
      onConfirm: () => {
        deleteU(`feedback/${id}`)
          .then((resp) => {
            if (resp.status === 'success') {
              loadUsers();
            }
          })
          .catch((e) => {
            // console.log("opened")
            // console.log(e.response)
          });
      }
    });
  };

  useEffect(() => {
    loadUsers();
  }, [page, limit, searchParams]);

  if (isLoaded) {
    return (
      <div className="loader">
        <BallTriangle
          height="100"
          width="100"
          color="grey"
          ariaLabel="loading"
        />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>FeedBacks</title>
      </Helmet>
      <Box className="headerWrapper">
        <Box className="headerTitle">Обратная связь</Box>
      </Box>
      <Box
        sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}
      >
        <Container maxWidth={false}>
          <>
            <Box sx={{ pt: 3 }}>
              <Card>
                <PerfectScrollbar>
                  <Box sx={{ minWidth: 1200 }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ width: 80,cursor: 'pointer' }}
                          onClick={() => handleChangeSort('id')}>Id
                          {searchParams.get('sort') ===
                              'id' ? (
                                <ArrowSort
                                  rotate={
                                    searchParams.get('order') == 'desc'
                                      ? 90
                                      : 270
                                  }
                                />
                              ) : (
                                <></>
                              )}</TableCell>
                          <TableCell
                          sx={{ cursor: 'pointer' }}
                          onClick={() => handleChangeSort('email')}>Почта
                          {searchParams.get('sort') ===
                              'email' ? (
                                <ArrowSort
                                  rotate={
                                    searchParams.get('order') == 'desc'
                                      ? 90
                                      : 270
                                  }
                                />
                              ) : (
                                <></>
                              )}
                          </TableCell>
                          <TableCell sx={{ cursor: 'pointer', width: 200 }}
                          onClick={() => handleChangeSort('isChecked')}>Обработано?
                          {searchParams.get('sort') ===
                              'isChecked' ? (
                                <ArrowSort
                                  rotate={
                                    searchParams.get('order') == 'desc'
                                      ? 90
                                      : 270
                                  }
                                />
                              ) : (
                                <></>
                              )}
                          </TableCell>
                          <TableCell
                          sx={{ cursor: 'pointer' }}
                          onClick={() => handleChangeSort('message')}>Сообщение
                          {searchParams.get('sort') ===
                              'message' ? (
                                <ArrowSort
                                  rotate={
                                    searchParams.get('order') == 'desc'
                                      ? 90
                                      : 270
                                  }
                                />
                              ) : (
                                <></>
                              )}
                          </TableCell>
                          <TableCell
                              sx={{ cursor: 'pointer' }}
                              onClick={() =>
                                handleChangeSort('creationDate')
                              }
                            >
                              Дата
                              {searchParams.get('sort') ===
                              'creationDate' ? (
                                <ArrowSort
                                  rotate={
                                    searchParams.get('order') == 'desc'
                                      ? 90
                                      : 270
                                  }
                                />
                              ) : (
                                <></>
                              )}
                            </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {feedBacks?.map((item) => (
                          <TableRow hover key={item.id}>
                            <TableCell sx={{ width: 80 }}>{item?.id}</TableCell>
                            <TableCell>{item?.email || '---'}</TableCell>
                            <TableCell>
                              {item?.isChecked ? 'Да' : 'Нет'}
                            </TableCell>
                            <TableCell>{item?.message || '---'}</TableCell>
                            <TableCell>
                            {item?.creationDate
                              ? new Date(item?.creationDate).toLocaleString()
                              : '---'}
                          </TableCell>
                            <TableCell>
                              <Box sx={{ display: 'flex' }}>
                                <Box sx={{ display: 'flex' }}>
                                  <Box sx={{ ml: 2 }}>
                                    <Link to={`/app/feedbacks/edit/${item.id}`}>
                                      <Button
                                        color="primary"
                                        variant="contained"
                                      >
                                        Редакировать
                                      </Button>
                                    </Link>
                                  </Box>
                                  <Box sx={{ ml: 2 }}>
                                    <Button
                                      color="error"
                                      variant="contained"
                                      onClick={() => onDelete(item.id)}
                                    >
                                      Удалить
                                    </Button>
                                  </Box>
                                </Box>
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            labelRowsPerPage={
                              <span>Кол-во строк на странице:</span>
                            }
                            rowsPerPageOptions={[10, 20, 30, 40, 50]}
                            colSpan={7}
                            count={count}
                            rowsPerPage={limit}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeLimit}
                          />
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </Box>
                </PerfectScrollbar>
              </Card>
            </Box>
          </>
        </Container>
      </Box>
    </>
  );
};

export default FeedBackList;
