import {
    Avatar,
    Box,
    Card,
    CardContent,
    Grid,
    Typography
} from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';

const StatisticsItem = ({ name, value, ...props }) => {

    return (
        <Card
            sx={{ height: '100%', borderRadius: 3,  backgroundColor: 'rgb(224,255,214)' }}
            {...props}
        >
            <CardContent>
                <Grid
                    container
                    spacing={3}
                    sx={{justifyContent: 'space-between'}}
                >
                    <Grid item>
                        <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h6"
                        >
                            {name}
                        </Typography>
                        <Typography
                            color="textPrimary"
                            variant="h3"
                        >
                            {value}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Avatar
                            sx={{
                                backgroundColor: red[600],
                                height: 56,
                                width: 56
                            }}
                        >
                            <PeopleIcon/>
                        </Avatar>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );

};

export default StatisticsItem;
